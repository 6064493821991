<template>
  <el-dialog  v-bind="{title:dialogTitle,width:'860px'}"
              @open="handlerOpen"
              @closed="handlerClose"
              :visible.sync="visibleDialog">

    <el-form :inline="true" ref="form" size="mini" label-position="right" label-width="200px"
             :rules="rules"
             :model="submitForm"
             style="margin-top: 20px">
      <el-row>
        <el-form-item label="合同编号:" prop="purcCode">
          <el-input
            v-model="submitForm.purcCode"
            style="width: 180px"
          />
        </el-form-item>
        <el-form-item label="采购人:">
          <el-input
            v-model="submitForm.purcName"
            style="width: 200px"
          >
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="采购数量(吨):" prop="purcMoun">
          <el-input
            v-model="submitForm.purcMoun"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="供应商:">
          <el-select
            v-model="submitForm.relaId"
            placeholder="请选择供应商"
            filterable
            clearable
            style="width: 200px"
          >
            <template v-for="item in relaList">
              <el-option
                v-if="typeof(submitForm.relaId) =='string'"
                :key="item.relaId"
                :label="item.firm"
                :value="''+item.relaId">
              </el-option>
              <el-option
                v-else-if="typeof(submitForm.relaId) =='number'"
                :key="item.relaId"
                :label="item.firm"
                :value="((''+item.relaId).trim() == '')?'':parseInt(item.relaId)">
              </el-option>
              <el-option
                v-else
                :key="item.relaId"
                :label="item.firm"
                :value="item.relaId">
              </el-option>
            </template>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="签订时间:">
          <el-date-picker
            v-model="submitForm.purcDatTim"
            type="datetime"
            placeholder="选择日期时间"
            default-time="00:00:00"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 180px"
          />
        </el-form-item>
      </el-row>
      <el-row>
        <p class="fs-16 bold mb-15">物品列表</p>
      </el-row>
      <el-row>
        <el-table border :data="submitForm.stocInList" style="width: 100%" >
          <el-table-column  prop="mateName" label="物品名称">
            <template scope="scope">
              <el-input size="mini" v-model="scope.row.mateName"  ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="mateMode" label="物品型号" style="width:6vw;" >
            <template scope="scope">
              <el-input size="mini" v-model="scope.row.mateMode"  ></el-input>
            </template>
          </el-table-column>
          <!--          <el-table-column prop="mateStan" label="物品规格" style="width:6vw;" >
            <template scope="scope">
              <el-input size="mini" v-model="scope.row.monOther"  ></el-input>
            </template>
          </el-table-column>-->
          <el-table-column prop="inNum" label="入库数量(吨)" style="width:6vw;" >
            <template scope="scope">
              <el-input size="mini" v-model="scope.row.inNum"  ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="物品金额(元)" style="width:6vw;" >
            <template scope="scope">
              <el-input size="mini" v-model="scope.row.amount"  ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="prodAddr" label="产地" style="width:6vw;" >
            <template scope="scope">
              <el-input size="mini" v-model="scope.row.prodAddr"  ></el-input>
            </template>
          </el-table-column>
          <el-table-column fixed="right"  label="操作" v-if="editAble">
            <template slot-scope="scope" >
              <el-button
                type="primary"
                icon="el-icon-minus"
                circle
                @click.native.prevent="removeMonRow(scope.$index, scope.row.mateId, submitForm.stocInList)"
                size="mini"
                title="删除"
              />
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row v-if="editAble">
        <div style="width: 100%;text-align: center;margin-top:10px;margin-bottom: 10px;"> <el-button
          type="primary"
          icon="el-icon-plus"
          circle
          @click="insertMonEvent()"
          size="mini"
          title="添加"
        /></div>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer" v-show="editAble" >
      <el-button @click="visibleDialog = false" size="mini">取消</el-button>
      <el-button type="primary" @click="submit" size="mini">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addModPurchase, delInStoc } from '@/apis/purchaseContract/index'
import { getComps, gianRelacsMore } from '@/apis/common'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    },
    relaItems: {
      type: Array,
      default () {
        return []
      }
    },
    regionOptions: {
      type: Array,
      default () {
        return []
      }
    },
    regionProps: {
      type: Object
    },
    regionArr: {
      type: Array,
      default () {
        return []
      }
    },
    editAble: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    }
  },
  data () {
    return {
      relaList: [],
      compList: [],
      loading: false,
      submitForm: {
        finaPurcId: '',
        firm: '',
        purcAmou: 0,
        purcCode: '',
        purcDatTim: '',
        purcMoun: 0,
        stocInList: []
      },
      dialogTitle: '',
      rules: {
        projName: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    insertMonEvent () {
      this.submitForm.stocInList.push({
        amount: 0,
        inNum: 0,
        mateCode: '',
        mateId: '',
        mateMode: '',
        mateName: '',
        matePric: 0,
        mateStan: '',
        measUnit: '',
        prodAddr: ''
      })
    },
    handleItemChange (val) {
    },
    removeMonRow (index, id, rows) { // 删除改行
      this.$confirm('您确定要删除该数据?', '删除数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        if (id) {
          delInStoc({
            ids: [id]
          }).then(res => {
            if (res.code == 200) {
              this.$message.success('删除成功！')
              rows.splice(index, 1)
            }
          })
        } else {
          rows.splice(index, 1)
        }
      })
    },
    submit () {
      // const pathLabels = this.$refs.editCascader.getCheckedNodes()[0] ? this.$refs.editCascader.getCheckedNodes()[0].pathLabels : []
      const param = this.submitForm
      addModPurchase(param).then(res => {
        if (res.code === 200) {
          if (this.submitForm.finaPurcId != '') {
            this.$message.success('修改成功！')
          } else {
            this.$message.success('添加成功！')
          }
          this.visibleDialog = false
          this.$emit('refresh')
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handlerOpen () {
      if (this.editAble) {
        this.dialogTitle = this.editData ? '新建采购合同' : '编辑采购合同'
      } else {
        this.dialogTitle = '采购合同详情'
      }
      if (this.editData) {
        Object.keys(this.submitForm).forEach((key) => {
          const value = this.editData[key]
          if (value) this.submitForm[key] = value
        })
      }
    },

    handlerClose () {
      this.$emit('update:editData', null)
      this.submitForm = {
        finaPurcId: '',
        firm: '',
        purcAmou: 0,
        purcCode: '',
        purcDatTim: '',
        purcMoun: 0,
        stocInList: []
      }
      this.$refs.form.resetFields()
    }
  },
  mounted () {
    getComps({})
      .then((res) => {
        if (res.code == 200) {
          this.compList = res.data.rows
        }
      })
      .catch((res) => {
        console.log(res)
      })
    // 20:企业下供应商
    gianRelacsMore({ relaType: 20 }).then((res) => {
      if (res.code == 200) {
        this.relaList = res.data.rows
      }
    })
      .catch((res) => {
        console.log(res)
      })
  }
}
</script>
<style lang="scss" scoped>
</style>
