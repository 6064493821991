import { render, staticRenderFns } from "./purchaseDetail.vue?vue&type=template&id=7de1b622&scoped=true&"
import script from "./purchaseDetail.vue?vue&type=script&lang=js&"
export * from "./purchaseDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7de1b622",
  null
  
)

export default component.exports