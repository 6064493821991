import http from '@/utils/http'
// 采购合同管理分页查询
export const getPurchasePage = p => http.post('/unibu/purc/page', p)

// 采购合同详情
export const getPurchaseOne = p => http.post('/unibu/purc/one', p)

// 添加修改采购合同
export const addModPurchase = p => http.post('/unibu/purc/addMod2', p)

// 逻辑删除采购合同
export const delPurchase = p => http.post('/unibu/purc/del', p)

// 物品入库(简单操作)
export const inStoc = p => http.post('/unibu/purc/inStoc', p)

// 物品入库(删除)
export const delInStoc = p => http.post('/unibu/purc/delInStoc', p)

// 采购合同导出
export const exportExcelPurchase = p => http.postFormExcel('/unibu/purc/exportExcel', p)

// 采购合同导入模版下载
export const downTemPurchase = p => http.postFormExcel('/unibu/purc/downloadTem', p)

// 采购合同导入
export const importExcelPurchase = p => http.postForm('/unibu/purc/importExcel', p)
