<template>
  <page-container>
    <div class="layout">
      <div style="width: 100%;display: flex;align-items: center;">
        <el-form :inline="true" :model="searchModel" style="margin-top: 20px;padding-left: 20px" size="mini">
          <el-form-item label="所属区域:">
            <el-cascader
              ref="myCascader"
              v-model="selectedOptions"
              clearable
              placeholder="请选择区域"
              :options="regionOptions"
              :props="regionProps"
              @change="handleItemChange"
              filterable
            ></el-cascader>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="submitSearch" :loading="loading">{{ searchText }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-close" v-if="loading" @click="loading = false">停止</el-button>
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          id="purchaseContract"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :align="allAlign"
          :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
          :data="tableData">
          <template #buttons>
            <el-button
              type="primary"
              v-auth="'采购合同_新增'"
              icon="el-icon-circle-plus-outline"
              @click="dialogEdit = true;purcEditModel = undefined"
            >新增采购合同
            </el-button
            >
            <el-upload
              action="#"
              class="upload"
              style="display:inline-block"
              :auto-upload="false" accept=".xlsx,.xls" :on-change="handleExcel" :show-file-list="false">
              <el-button
                v-auth="'采购合同_导入'"
                type="default"
                class="ml-10 mr-10"
                icon="el-icon-download"
              >导入
              </el-button></el-upload>
            <el-button
              v-auth="'采购合同_模板下载'"
              type="success"
              icon="el-icon-tickets"
              @click="
                downTemPurchase();
              "
            >模板下载
            </el-button>
          </template>
          <vxe-table-column type="seq" title="序号" width="60"/>
          <vxe-table-column field="projName" title="所属区域" show-overflow="title" min-width="120"  :formatter="
                              ({ celllValue, row, column }) => {
                                if (row.cityName != '直辖区')
                                  return row.provinName + row.cityName + row.countrName;
                                else return row.provinName + row.countrName;
                              }
                            "
                            show-header-overflow="title"/>
          <vxe-table-column field="compName" title="企业名称" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="purcCode" title="合同编号" show-overflow="title" min-width="100"
                            show-header-overflow="title"/>
          <vxe-table-column field="firm" title="供应商" show-overflow="title" min-width="120"
                            show-header-overflow="title"/>
          <vxe-table-column field="purcDatTim" title="签订时间" min-width="60" show-overflow="title"
                            show-header-overflow="title"/>
          <vxe-table-column title="操作" min-width="100">
            <template v-slot="{ row }">
              <el-button type="default" icon="el-icon-upload2" circle size="mini" @click="exportTabData(row)"
                         v-auth="'采购合同_导出'" title="导出"/>
              <el-button type="success" icon="el-icon-tickets" circle size="mini" @click="editAble=false;detailRow(row)"
                         v-auth="'采购合同_详情'" title="详情"/>
              <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editAble=true;detailRow(row)"
                         v-auth="'采购合同_修改'" title="修改"/>
              <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deleteRow(row)"
                         v-auth="'采购合同_删除'" title="删除"/>
            </template>
          </vxe-table-column>
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
        @page-change="handlePageChange"
      />
      <!-- 编辑采购合同 -->
      <purchaseDetail :visible.sync="dialogEdit" :edit-data.sync="purcEditModel" @refresh="refreshData"  :region-options="regionOptions" :region-props="regionProps" :edit-able ="editAble"/>
    </div>
  </page-container>
</template>

<script>
import purchaseDetail from './purchaseDetail'
import { getPurchasePage, delPurchase, getPurchaseOne, exportExcelPurchase, downTemPurchase, importExcelPurchase } from '@/apis/purchaseContract/index'
import { gainRegi } from '@/apis/common'
import XEUtils from 'xe-utils'
import { exportToxlsx } from '@/utils/tools'

export default {
  name: 'purchase-contract',
  data () {
    return {
      editAble: true,
      searchModel: {
        regiCountrId: '',
        regiCityId: '',
        regiProvinId: ''
      },
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      },
      searchText: '查询',
      allAlign: 'center',
      tableData: [],
      regionArr: [],
      regionOptions: [],
      regionProps: {
        // multiple: true,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level
          if (level === 1) {
            resolve(node.children[0].label == '直辖区' ? [] : node.children)
            // resolve(node.children)
          }
          if (level === 2 && node.data.code) {
            if (node.children && node.children.lenght > 0) {
              resolve(node.children)
            } else {
              setTimeout(() => {
                gainRegi({
                  isMapJson: false,
                  regiLevel: node.level,
                  regiCodes: [node.data.code]
                }).then((res) => {
                  if (res.code == 200) {
                    const nodeArr = res.data[0].regiCountrs.map((val) => ({
                      value: val.regiCountrId,
                      label: val.countrName,
                      code: val.countrCode,
                      leaf: true
                    }))
                    resolve(nodeArr)
                  }
                })
              })
            }
          } else if (level == 3) {
            resolve([])
          }
        }
      },
      dialogDetail: false,
      editMode: 'add',
      projDetailModel: {},
      // 所属区域
      selectedOptions: [],
      loading: false,
      dialogEditTitle: '新增采购合同',
      dialogEdit: false,
      purcEditModel: {
      }
    }
  },
  methods: {
    handleItemChange (val) {
    },
    // 导入
    handleExcel (file) {
      const formData = new FormData()
      formData.append('file', file.raw)
      formData.append('num', 1)
      importExcelPurchase(formData).then(res => {
        if (res.code == '200') {
          this.$message.success('文件导入成功！')
          this.refreshData()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 导出
    exportTabData (row) {
      const formData = new FormData()
      formData.append('id', row.finaPurcId)
      exportExcelPurchase(formData).then(res => {
        exportToxlsx(res, '采购合同.xlsx')
      })
    },
    // 模板下载
    downTemPurchase () {
      downTemPurchase().then(res => {
        exportToxlsx(res, '采购合同模板.xlsx')
      })
    },
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    submitSearch () {
      this.tableData = []
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    detailRow (row) {
      getPurchaseOne({
        id: row.finaPurcId
      }).then(res => {
        const details = res.data
        const rowData = {}
        XEUtils.objectEach(details, (item, key) => {
          if (key == 'stocInList') {
            const tempArr = []
            details[key].forEach(eve => {
              tempArr.push({
                amount: eve.amount,
                inNum: eve.inNum,
                mateCode: eve.mateCode,
                mateId: eve.mateId,
                mateMode: eve.mateMode,
                mateName: eve.mateName,
                matePric: eve.matePric,
                measUnit: eve.measUnit,
                prodAddr: eve.prodAddr
              })
            })
            rowData[key] = tempArr
          } else {
            rowData[key] = details[key] ? details[key].toString() : ''
          }
        })
        this.regionArr = []
        if (details.regiCountrId) {
          gainRegi({
            isMapJson: false,
            regiLevel: 2,
            regiDto: {} /*  */
          }).then((res) => {
            const targetProvin = this.regionOptions.find(
              (item) => item.value == details.regiProvinId
            )
            let targetCity = res.data.find(
              (item) => item.regiCityId == details.regiCityId
            )
            targetCity = {
              value: targetCity.regiCityId,
              label: targetCity.cityName,
              code: targetCity.cityCode,
              children: targetCity.regiCountrs.map((item) => {
                return {
                  value: item.regiCountrId,
                  label: item.countrName,
                  code: item.countrCode,
                  leaf: true
                }
              })
            }
            targetProvin.children.forEach((item, index) => {
              if (item.value == details.regiCityId) {
                this.$set(targetProvin.children, index, targetCity)
              }
            })
          })
        }
        if (details.regiProvinId != '' || details.regiProvinId != '0') {
          this.regionArr.push(details.regiProvinId)
        }
        if (details.regiCityId != '' || details.regiCityId != '0') {
          this.regionArr.push(details.regiCityId)
        }
        if (details.regiCityId != '' || details.regiCityId != '0') {
          this.regionArr.push(details.regiCountrId)
        }
        this.purcEditModel = Object.assign({}, rowData)
        this.dialogEdit = true
      })
    },
    deleteRow (row) {
      this.$confirm('确定删除该采购记录?', '删除采购记录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        delPurchase({
          ids: [row.finaPurcId]
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    refreshData () {
      this.loading = true
      getPurchasePage({
        regiCountrId: this.selectedOptions[2]
          ? this.selectedOptions[2]
          : undefined,
        regiCityId: this.selectedOptions[1]
          ? this.selectedOptions[1]
          : undefined,
        regiProvinId: this.selectedOptions[0]
          ? this.selectedOptions[0]
          : undefined,
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.currentPage
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.data.rows
          this.tablePage.total = res.data.totalSize
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      }).catch(error => {
        console.log(error)
        this.logining = false
      })
    }
  },
  mounted () {
    // 所属区域
    gainRegi({
      regiLevel: 1,
      isMapJson: false,
      regiDto: {}
    }).then((res) => {
      if (res.code == 200) {
        const regionOptions = res.data.map((val) => ({
          value: val.regiProvinId,
          label: val.provinName,
          code: val.provinCode,
          children: val.regiCitys.map((valCity) => ({
            value: valCity.regiCityId,
            label: valCity.cityName,
            code: valCity.cityCode,
            children: []
          }))
        }))
        this.regionOptions = regionOptions
      }
    })
      .catch((val) => {
        console.log(val)
      })
    this.refreshData()
  },
  components: {
    purchaseDetail
  }
}
</script>

<style lang="scss" scoped>
</style>
